import React from "react";
import { Formik, Form } from "formik";
import { Basic } from "./ModifiedSearch/Basic";
// import { EyeClean } from "./ModifiedSearch/EyeClean";
import { Advanced } from "./ModifiedSearch/Advanced";
import { Parameter } from "./ModifiedSearch/Parameter";
import { Additional } from "./ModifiedSearch/Additional";
import axios from "axios";
// import PerfectScrollbar from "react-perfect-scrollbar";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import { useHistory } from "react-router-dom";
// import {toast} from "react-toastify"
import Cookies from "js-cookie";
import { FaSearch } from "react-icons/fa";
// import { Backdrop, CircularProgress } from "@material-ui/core";

// const perfectScrollbarOptions = {
//     wheelSpeed: 1,
//     wheelPropagation: false,
// };

function ModifiedSearchOffcanvas(props) {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const history = useHistory()


    // const enableLoading = () => {
    //     // setLoading(true);
    //     // setOpenLoader(true);
    //     setIsSubmitting(true)
    // };

    const disableLoading = () => {
        // setLoading(false);
        // setOpenLoader(false);
        setIsSubmitting(false)
    };
    function handleData(values,oldvalues) {
        // enableLoading();
        props.setLoader(true)
        const fancy = oldvalues.diamond_type === "N"?Cookies.get("fancy")?1:0:Cookies.get("fancybasic")?1:0
    const userData = localStorage.getItem("userData")
    const user = JSON.parse(userData);
    const id = localStorage.getItem("C_id");
        let len = Object.keys(values).length;
        const temp = values;
        let data = "";
        for (let key in temp) {
            len--;
            let arr = temp[key];
            if (len) {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                }
            } else {
                if (Array.isArray(arr)) {
                    let str = "";
                    str += "'" + arr.join("','") + "'";
                    data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                } else {
                    data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                }
            }
        }
        const finalData = `{ "JsonData":  [${data}] , "CustomerId": "${props.userinfo.id}", "ConsumerId":${ id === "" ? "-1" : id }, "WLDiamondType": "${oldvalues.diamond_type}" ,"page":1, "conversionrate":"${props.conversionrate}","fancy":"${fancy}" }`;
        props.setPageNumber(1)
        if(oldvalues.diamond_type === "N"){
            localStorage.setItem("modifysearch",JSON.stringify(values))
        }
        else{
            localStorage.setItem("modifysearchlab",JSON.stringify(values))
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/searchDiamond`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        })
        .then(function(res) {
            props.setSwipeableDrawer('right', false)    
            props.setStockAPI(res.data.data)
            disableLoading();
            // props.setLoader(false)
            history.push({
                pathname: `/search-result/${oldvalues.diamond_type}`,
                state: {
                    searchresult:res.data.data,
                    oldvalues:oldvalues,
                    page:1
                },
                    // state: decompress(res.data.ProductDetails),
                });
                props.setSortObj({id:"",sortorder:""})
                props.setLoader(false)
                props.setUpdateCount(!props.updatecount)
                // axios({
                //     method: "POST",
                //     url: `${NODE_API_URL}/INTL/SearchCountFunctionality`,
                //     headers: { "Content-Type": "application/json" },
                //     data: finalData,
                // })
                // .then(function(rescount) {
                //     // setStoneCount(JSON.stringify(rescount.data.data));
                    
                //     window.localStorage.setItem("stoneCount", rescount.data.data)
                //     props.setLoader(false)
                // })      
        })
        .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }
    return (
        <>
            <div style={{ width: "380px" }}>
                <div className="row m-0 mt-5">
                    <div className="col-12 border-bottom d-flex justify-content-between align-items-center sticky-top bg-white">
                        <p className="font-size-h3 ml-5 font-weight-bold">
                            {props.language.MODIFY_SEARCH_EDIT_FILTER}
                        </p>
                    </div>
                    
                        <Formik
                            enableReinitialize={true}
                            initialValues={{}}
                            onSubmit={async (values, { setSubmitting }) => {
                                // enableLoading();
                                let oldvalues =values
                                Object.keys(values).forEach((key) => {
                                    if (
                                        values[key] === "" ||
                                        values[key] === null ||
                                        values[key].length === 0
                                    ) {
                                        delete values[key];
                                    }
                                });
                                setTimeout(() => {
                                    handleData(values,oldvalues);
                                }, 1000);
                            }}
                        >
                            {(formik, handleSubmit) => (
                                <>
                                    <Form className="w-100 globleFilter searchDiamondBox ">
                                        <div className={`col-12 border-bottom d-flex justify-content-between align-items-center mt-5 modified-search-canvas ${props.diamond_type === "L"? "labTab" :"natTab" }`}>
                                            <div
                                                className="custom-scrollbar w-100"
                                                style={{
                                                    maxHeight: "83vh",
                                                    position: "relative",
                                                }}
                                            >
                                                <div className="mb-8">
                                                    <Basic language={props.language} diamond_type={props.diamond_type} formik={formik} oldvalues={props.oldvalues}/>
                                                </div>
                                                {/* <div className="mb-8">
                                                    <EyeClean language={props.language} formik={formik}/>
                                                </div> */}
                                                <div className="mb-8">
                                                    <Advanced language={props.language} formik={formik}/>
                                                </div>
                                                <div className="mb-8">
                                                    <Parameter language={props.language} formik={formik}/>
                                                </div>
                                                <div className="mb-8">
                                                    <Additional language={props.language} diamond_type={props.diamond_type} formik={formik}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-12 my-2 text-right ${props.diamond_type === "L"? "labTab" : "natTab" }`}>
                                            <button className="btn btn-text-danger btn-hover-light-danger font-weight-bold px-10" onClick={() =>   props.diamond_type === "L"?localStorage.removeItem("modifysearchlab"):localStorage.removeItem("modifysearch")}>
                                                {props.diamond_type === "L"?<a className="text-danger" href='/labsearchdiamond/white'> RESET</a>:<a className="text-danger"  href='/searchdiamond/white'> RESET</a>}
                                            </button>
                                            <button
                                                type="submit"
                                                className={`btn btn-primary px-10 font-weight-bold  ml-2 ${isSubmitting ? "cursor-na" : ""}`}
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                 <FaSearch className="mr-2 font-size-h5" />
                                                {isSubmitting
                                                    ? "Please wait..."
                                                    : props.language.MODIFY_SEARCH_EDIT_SEARCH}
                                                
                                            </button>
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    
                </div>
            </div>
        </>
    );
}                                   

export default ModifiedSearchOffcanvas;
