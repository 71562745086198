/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect} from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { ImPrinter } from 'react-icons/im';
import {SuccessDiamondDetails} from "./SuccessDiamondDetails"
import axios from "axios"
import { NODE_API_URL } from "../../../env_config";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
export const PaymentSuccessNewCustomer = (props) => {
    const params = useLocation()
    const [invoicedetail,setInvoiceDetails] = useState([])
    const [orderdetail,setOrderDetails] = useState([])
    const [invoicelink,setInvoiceLink] = useState("")
    const [userinfo,setUserInfo] = useState(null)
    const [vipdiscount] = useState(0)
    const [loader,setLoader] = useState(false)
    const [sessionid, setSessionId] = useState(null);

    useEffect(() => {
        const session_id = new URLSearchParams(params.search).get("session_id");
        setSessionId(session_id); // Update sessionid state
    }, [params.search]);

    useEffect(() => {
        const fetchData = async () => {
            if (!sessionid) return;

            setLoader(true);

            try {
                const res = await axios.post(`${NODE_API_URL}/INTL/createInvoice`, {
                    stripeToken: sessionid,
                }, {
                    headers: { 'Content-Type': 'application/json' }
                });

                if (res?.data?.data?.msg === "Invoice generated") {
                    setInvoiceDetails(res.data.data.invoice_data || []);
                    setOrderDetails(res.data.data.order_data || []);
                    setInvoiceLink(res.data.data.invoice_link);
                    setUserInfo(res.data.data.userinfo);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoader(false);
            }
        };

        const timer = setTimeout(() => {
            fetchData(); // Call the fetchData function after a delay
        }, 500); // Adjust the debounce delay as needed

        return () => clearTimeout(timer); // Cleanup timer on unmount or dependency change
    }, [sessionid]); // Trigger on changes to sessionid 
    const GetPDF = () => {
        if(process.env.REACT_APP_ENV === "production"){
            window.open(`https://assets.diamondsoncall.com/assets/pdf_invoice/${invoicelink}.pdf`, "_blank")
        }
        else{
            window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${invoicelink}.pdf`, "_blank")
        }
    }
    function numberWithCommas(x) {
    if(x){
        return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else{
        return "0.00"
    }
}
const getaddress = () => {
    let shippingaddress = ""
    let billingaddress = ""
    if(orderdetail[0]){
        if(orderdetail[0].company_name_official){
            shippingaddress += "<p><b>"
            shippingaddress += orderdetail[0].company_name_official
            shippingaddress += "</b></p>"
        }
        if(orderdetail[0]?.name_atten){
            shippingaddress += "<p><b>"
            shippingaddress += orderdetail[0]?.name_atten
            shippingaddress += "</b></p>"
        }
        if(orderdetail[0]?.shipping_address_1){
          shippingaddress += "<p>"
          shippingaddress += orderdetail[0]?.shipping_address_1 + ","
          shippingaddress += "</p>"
        }
        if(orderdetail[0]?.shipping_address_2){
            shippingaddress += "<p>"
            shippingaddress += orderdetail[0]?.shipping_address_2 + ","
            shippingaddress += "</p>"
        }
        if(orderdetail[0]?.shipping_address_3){
            shippingaddress += "<p>"
            shippingaddress += orderdetail[0]?.shipping_address_3 + ","
            shippingaddress += "</p>"
        }
        shippingaddress += "<p>"
        shippingaddress += orderdetail[0]?.shipping_city + ", "
        if(orderdetail[0]?.shipping_state){
            shippingaddress += orderdetail[0]?.shipping_state + ", "
        }
        shippingaddress += orderdetail[0]?.shipping_zip_code + ", "
        shippingaddress += "</p>"
        if(orderdetail[0]?.shipping_country){
            shippingaddress += "<p>"
            shippingaddress += orderdetail[0]?.shipping_country
            shippingaddress += "</p>"
        }
        if(orderdetail[0]?.fedex_ac_no){
            shippingaddress += "<p>Fedex Ac No:"
            shippingaddress += orderdetail[0]?.fedex_ac_no
            shippingaddress += "</p>"
        }
        if(orderdetail[0]?.shipping_email){
            shippingaddress += "<p>Email:"
            shippingaddress += orderdetail[0]?.shipping_email
            shippingaddress += "</p>"
        }
        if(orderdetail[0]?.shipping_phone){
            shippingaddress += "<p>Phone:"
            shippingaddress += orderdetail[0]?.shipping_phone
            shippingaddress += "</p>"
        }
    
        
    
        if(orderdetail[0].company_name_official){
            billingaddress += "<p><b>"
            billingaddress += orderdetail[0].company_name_official
            billingaddress += "</b></p>"
        }
        if(orderdetail[0].address){
          billingaddress += "<p>"
          billingaddress += orderdetail[0].address + ","
          billingaddress += "</p>"
        }
        if(orderdetail[0].secaddress){
            billingaddress += "<p>"
            billingaddress += orderdetail[0].secaddress + ","
            billingaddress += "</p>"
        }
        if(orderdetail[0].addressline3){
            billingaddress += "<p>"
            billingaddress += orderdetail[0].addressline3 + ","
            billingaddress += "</p>"
        }
        billingaddress += "<p>"
        billingaddress += orderdetail[0]?.city + ", "
        if(orderdetail[0]?.state){
            billingaddress += orderdetail[0]?.state + ", "
        }
        billingaddress += orderdetail[0]?.com_zip + ","
        billingaddress += "</p>"
        if(orderdetail[0].country){
            billingaddress += "<p>"
            billingaddress += orderdetail[0].country
            billingaddress += "</p>"
        }
        if(orderdetail[0].company_tax){
            billingaddress += "<p><b>Importers Ref:</b>"
            billingaddress += orderdetail[0].company_tax
            billingaddress += "</p>"
        }
    }
    return{
        shippingaddress:shippingaddress,
        billingaddress:billingaddress
    }
}

    return (
        <>
            
            {loader?<div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Please Wait While Invoice is Been Generated..</span></div>:invoicedetail.length > 0 && orderdetail.length > 0?<div className="card bg-white custom-card">
                <div className="card-body">
                    <div className="row mt-5 justify-content-center mb-10">
                        <div className="col-12 text-center">
                            <SVG  height={50}  width={50}  src={toAbsoluteUrl("/media/General/ic_fest.svg")} />
                            <div className="display-3 mt-4 text-primary">{props.language.PAYMENT_SUCCESS_THANKYOU}</div>
                            <p className="text-dark mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_PAR1} <strong>{orderdetail[0].invoicenumber}</strong></p>
                            <p className="text-dark mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_PAR2} {userinfo?.mail} {props.language.PAYMENT_SUCCESS_PAR3}</p>
                            <p className="text-muted mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_PLACEDON} : {orderdetail[0].order_date.split('T')[0]} </p>
                            {invoicelink?<a className="mt-4 font-weight-bold" onClick={() => GetPDF(orderdetail[0].invoicenumber)} disabled={!invoicelink}>{props.language.PAYMENT_SUCCESS_VIEW_INVOICE} <ImPrinter className="ml-2 font-size-lg"/></a>:<a className="mt-4 font-weight-bold">{props.language.PAYMENT_SUCCESS_GENERATING_INVOICE} ...<ImPrinter className="ml-2 font-size-lg"/></a>}
                        </div>
                        <div className="col-md-8 border-top pt-5 mt-5">
                            <div className="row mt-5">
                                <div className="col-md-4 border-md-right">
                                    <div className="font-size-h3 mb-4 font-weight-bold">{props.language.CHECKOUT_DELIVERY_INFORMATION}</div>
                                    <div>
                                    <div className="text-capitalize">
                                            <div>
                                                <div dangerouslySetInnerHTML={ {__html:  getaddress().shippingaddress} } />
                                            </div>
                                         <br />
                                    </div>
                                    </div>
                                </div> 
                                <div className="col-md-4 border-md-right">
                                    <div className="font-size-h3 mb-4 font-weight-bold">{props.language.CHECKOUT_BILLING_INFORMATION}</div>
                                    <div>
                                        <div dangerouslySetInnerHTML={ {__html:  getaddress().billingaddress} } />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="font-size-h3 mb-4 font-weight-bold">{props.language.CHECKOUT_SHIPPING_METHOD}</div>
                                    <p className="mb-2 font-weight-bold">{orderdetail[0].pre_carriage}</p>
                                    {/* <p>( Delivery will be in 1-2 days, If delay well notify you )</p> */}
                                </div> 
                            </div>
                        </div>
                        <div className="col-md-8 mt-5 mt-md-10 border-bottom pb-5 mt-5">
                            <div className="font-weight-bold mb-4 font-size-h3">{props.language.PROFORMA_DETAIL_ORDER_SUMMARY}<span className="small ml-2">({invoicedetail.length} {props.language.CHECKOUT_ITEMS})</span></div>
                            <SuccessDiamondDetails data={invoicedetail} language={props.language}/>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> {props.language.PROFORMA_DETAIL_SUB_TOTAL}: </div>
                                    <div> ${numberWithCommas(Math.round((parseFloat(orderdetail[0].total_amt) - parseFloat(orderdetail[0].shipping_charge) - parseFloat(orderdetail[0].credit_card_charge) + parseFloat(orderdetail[0].discount_extra)) * 100)/100)} </div>
                                </div>
                                {parseFloat(orderdetail[0].shipping_charge)?<div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> {props.language.REQ_DIAMOND_CONVINIENCE_FEE}: </div>
                                    <div> ${numberWithCommas(orderdetail[0].shipping_charge)} </div>
                                </div>:""}
                                {orderdetail[0].discount_extra !== "0"?<div className="col-12 d-flex mt-2 justify-content-between font-weight-bold">
                                    <div> {props.language.INVOICE_DISCOUNT}: </div>
                                    <div> ${numberWithCommas(orderdetail[0].discount_extra)} </div>
                                </div>:""}
                                {vipdiscount?<div className="col-12 d-flex mt-2 justify-content-between font-weight-bold border-bottom border-top py-3">
                                    <div className="font-size-lg"> {props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}: </div>
                                    <div className="font-size-lg text-danger"> -${numberWithCommas(vipdiscount)} </div>
                                </div>:""}
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold border-bottom border-top py-3">
                                    <div className="font-size-lg"> {props.language.SEARCHGRID_TOTAL}: </div>
                                    <div className="font-size-lg"> ${numberWithCommas(orderdetail[0].total_amt - orderdetail[0].credit_card_charge - vipdiscount)} </div>
                                </div>
                                <div className="col-12 d-flex mt-2 justify-content-between font-weight-bold border-bottom border-top py-3">
                                    <div className="font-size-lg"> {props.language.INVOICE_CREDIT_CARD_CHARGES}: </div>
                                    <div className="font-size-lg"> ${numberWithCommas(orderdetail[0].credit_card_charge)} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:
            <div className="card bg-white custom-card">
            <div className="card-body">
                <div className="row mt-5 justify-content-center mb-10">
                    <div className="col-12 text-center">
                        <div className="display-3 mt-4 text-primary">FORBIDDEN</div>
                    </div>
                </div>
            </div>
        </div>
            }

        </>
    );
};
